import React from 'react';

//Data
import { data } from '@data/en/pages/about';

//Components
import SEO from '@components/Seo/Seo';
import { Header } from '@components/Header/Header';
import { Hero } from '@components/Hero/Hero';
import { CTA } from '@components/CTA/CTA';
import { ImagesSlider } from '@components/ImagesSlider/ImagesSlider';
import { EmployeesList } from '@components/EmployeesList/EmployeesList';
// import { FeaturedCarreers } from '@components/FeaturedCarreers/FeaturedCarreers';
import { FeaturedPartners } from '@components/FeaturedPartners/FeaturedPartners';
import { SuperPowers } from '@components/SuperPowers/SuperPowers';
import { OurApproach } from '@components/OurApproach/OurApproach';
import { Footer } from '@components/Footer/Footer';

const About = () => {
    const {
        seo,
        path,
        hero,
        problems_we_solve,
        our_approach,
        cta,
        featured_partners,
        images_slider,
        employees,
        // featured_carreers,
    } = data;

    return (
        <>
            <SEO {...seo} path={path} />

            <Header />

            <Hero small title={hero.title} text={hero.text} />

            <SuperPowers title={problems_we_solve.title} powers={problems_we_solve.powers} />

            <OurApproach slides={our_approach.slides} />

            <CTA title={cta.title} text={cta.text} href={cta.href} />

            <FeaturedPartners
                title={featured_partners.title}
                text={featured_partners.text}
                button={featured_partners.button}
            />

            <ImagesSlider images={images_slider.images} />

            {/* <EmployeesList title={employees.title} /> */}

            {/* <FeaturedCarreers
                title={featured_carreers.title}
                text={featured_carreers.text}
                button={featured_carreers.button}
            /> */}

            <Footer />
        </>
    );
};

export default About;
