import React from 'react';

//Images
import ArrowLeftBlack from '@images/icons/arrow-left-black.svg';
import ArrowRightBlack from '@images/icons/arrow-right-black.svg';

export const PrevArrow = props => {
    const { className, customClasses, onClick } = props;

    return (
        <button className={`${className} ${customClasses}`} onClick={onClick}>
            <ArrowLeftBlack />
        </button>
    );
};

export const NextArrow = props => {
    const { className, customClasses, onClick } = props;

    return (
        <button className={`${className} ${customClasses}`} onClick={onClick}>
            <ArrowRightBlack />
        </button>
    );
};
