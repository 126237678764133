import React, { useState, useRef } from 'react';
import '@styles/lib/slick.scss';
import Slider from 'react-slick';
import { motion, AnimatePresence } from 'framer-motion';

//Styles
import styles from './OurApproach.module.scss';

//Components
import { Container } from '@components/Utilities/Container/Container';
import { Cursor } from '@components/Cursor/Cursor';
import { Link } from 'gatsby';
import { NextArrow, PrevArrow } from './Arrows';

//Images
import ArrowRightUpBlack from '@images/icons/arrow-right-up-black.svg';

export const OurApproach = props => {
    const { slides = [] } = props;
    const [currSlideIndex, setCurrSlideIndex] = useState(0);
    const [cursorActive, setCursorActive] = useState(false);
    const refSlider = useRef();

    const settings = {
        arrows: false,
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        variableWidth: true,
        appendDots: dots => (
            <div style={{ position: 'absolute', top: 'calc(100% + 2.5rem)', left: 0 }}>
                <ul className={styles.ourApproach__nav}>{dots}</ul>
            </div>
        ),
        customPaging: index => (
            <div
                className={`${styles.ourApproach__navItem} ${
                    index === currSlideIndex ? styles.ourApproach__navItemActive : ''
                }`}
                key={index}
            >
                <button
                    className={styles.ourApproach__button}
                    type="button"
                    aria-label={`Change slider to slide ${index}`}
                ></button>
            </div>
        ),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: true,
                    prevArrow: (
                        <PrevArrow
                            customClasses={`${styles.ourApproach__arrow} ${
                                styles.ourApproach__arrowPrev
                            } ${currSlideIndex !== 0 ? styles.ourApproach__arrowActive : ''}`}
                        />
                    ),
                    nextArrow: (
                        <NextArrow
                            customClasses={`${styles.ourApproach__arrow} ${
                                styles.ourApproach__arrowNext
                            } ${
                                currSlideIndex !== slides.length - 1
                                    ? styles.ourApproach__arrowActive
                                    : ''
                            }`}
                        />
                    ),
                },
            },
            {
                breakpoint: 575,
                settings: {
                    variableWidth: false,
                    arrows: true,
                    prevArrow: (
                        <PrevArrow
                            customClasses={`${styles.ourApproach__arrow} ${
                                styles.ourApproach__arrowPrev
                            } ${currSlideIndex !== 0 ? styles.ourApproach__arrowActive : ''}`}
                        />
                    ),
                    nextArrow: (
                        <NextArrow
                            customClasses={`${styles.ourApproach__arrow} ${
                                styles.ourApproach__arrowNext
                            } ${
                                currSlideIndex !== slides.length - 1
                                    ? styles.ourApproach__arrowActive
                                    : ''
                            }`}
                        />
                    ),
                },
            },
        ],
    };

    if (!slides.length) return null;

    const renderedSlides = slides.map((slide, index) => {
        const { main_title, tasks = [], text = [], button } = slide;

        return (
            <div
                role="presentation"
                onMouseEnter={() => setCursorActive(true)}
                onMouseLeave={() => setCursorActive(false)}
                className={`${styles.ourApproach__slide} ${
                    index === 0 ? styles.ourApproach__slideFirst : ''
                } ${index === currSlideIndex ? styles.ourApproach__slideActive : ''}`}
                key={index}
            >
                {main_title && (
                    <h2
                        className={`${styles.ourApproach__mainTitle} h1`}
                        dangerouslySetInnerHTML={{ __html: main_title }}
                    ></h2>
                )}

                {tasks && (
                    <p className={styles.ourApproach__tasks}>
                        {tasks.map((task, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <span className={styles.ourApproach__task}>{task}</span>

                                    <span className={styles.ourApproach__dash}>
                                        {`${index + 1 !== tasks.length ? ' - ' : ''}`}
                                    </span>
                                </React.Fragment>
                            );
                        })}
                    </p>
                )}

                {text.map((p, index) => {
                    return (
                        <p
                            key={index}
                            className={styles.ourApproach__text}
                            dangerouslySetInnerHTML={{ __html: p }}
                        ></p>
                    );
                })}

                {button && (
                    <Link
                        onMouseEnter={() => {
                            if (currSlideIndex === index) setCursorActive(false);
                        }}
                        onMouseLeave={() => {
                            if (currSlideIndex === index) setCursorActive(true);
                        }}
                        className={`${styles.ourApproach__link} ${
                            currSlideIndex !== index ? styles.ourApproach__linkDisabled : ''
                        }`}
                        to={button.href}
                    >
                        {button.text}
                        <ArrowRightUpBlack />
                    </Link>
                )}
            </div>
        );
    });

    return (
        <section className={styles.ourApproach}>
            <Container>
                <AnimatePresence exitBeforeEnter>
                    {slides.map(({ slide_title }, index) => {
                        return index === currSlideIndex ? (
                            <motion.h3
                                key={index}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1, transition: { duration: 0.5 } }}
                                exit={{ opacity: 0 }}
                                className="h3"
                            >
                                {slide_title}
                            </motion.h3>
                        ) : null;
                    })}
                </AnimatePresence>
            </Container>

            <Container>
                {/* Global games slider styling found in styles/sliders/our-approach-slider.scss */}
                <Slider
                    ref={refSlider}
                    className="global-our-approach-slider"
                    beforeChange={(oldIndex, newIndex) => {
                        setCurrSlideIndex(newIndex);
                    }}
                    {...settings}
                >
                    {renderedSlides}
                </Slider>

                <Cursor cursorActive={cursorActive} type="slider-red" />
            </Container>
        </section>
    );
};
