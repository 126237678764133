import React, { useState } from 'react';
import '@styles/lib/slick.scss';
import Slider from 'react-slick';

//Data
import settings from './settings';

//Styles
import styles from './ImagesSlider.module.scss';

//Components
import { Cursor } from '@components/Cursor/Cursor';
import { Image } from './Image';

export const ImagesSlider = props => {
    const [cursorActive, setCursorActive] = useState(false);

    const images = props.images.map((image, index) => {
        return (
            <Image className={styles.imageWrapper} path={image.path} alt={image.alt} key={index} />
        );
    });

    return (
        <section
            role="presentation"
            onMouseEnter={() => setCursorActive(true)}
            onMouseLeave={() => setCursorActive(false)}
            className={styles.slider}
        >
            <Slider {...settings}>{images}</Slider>
            <Cursor cursorActive={cursorActive} type="slider" />
        </section>
    );
};
