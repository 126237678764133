export const mikeyHaklander = {
    avatar: {
        path: 'employees/mikeyhaklander/mike-avatar.png',
        alt: 'Mikey Haklander avatar',
    },
    first_name: 'Mikey',
    last_name: 'Haklander',
    role: 'Development',
    linkedin: 'https://www.linkedin.com/in/mike-haklander-114234a8/',
    mail: 'mike@the-pack.nl',
    // phone_number: '0655384102',
};
