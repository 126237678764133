const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '30%',
    swipeToSlide: true,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 1700,
            settings: {
                centerPadding: '20%',
            },
        },
        {
            breakpoint: 991,
            settings: {
                centerPadding: '15%',
            },
        },
        {
            breakpoint: 768,
            settings: {
                centerPadding: '10%',
            },
        },
    ],
};

export default settings;
