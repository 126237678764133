import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

//Components
import Img from 'gatsby-image';

export const Image = props => {
    const { className, path, alt = '' } = props;

    const data = useStaticQuery(graphql`
        query {
            images: allFile {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(maxWidth: 1200, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp_noBase64
                            }
                        }
                        publicURL
                    }
                }
            }
        }
    `);

    const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(path);
    });

    if (!image) return null;

    const extension = path.match(/[^\\]*\.(\w+)$/)[1];
    if (extension === 'svg' || extension === 'gif') {
        return <img loading="lazy" className={className} src={image.node.publicURL} alt={alt} />;
    }

    return <Img className={className} fluid={image.node.childImageSharp.fluid} alt={alt} />;
};
